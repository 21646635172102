import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button, 
  Link,  
  Skeleton,  
  Typography 
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import LoadingButton from '../../../../components/buttons/Button';
import NA from '../../../../components/utils/NA';

import { 
  GetPaymentHandle 
} from '../../../../redux/actions/receive/paymentHandleActions';
import { 
  CreatePaymentQrCode,
  GetPaymentQrCode 
} from '../../../../redux/actions/receive/paymentQrCodeActions';

import  "./styles.scss";

const PaymentLinkPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.receive);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  const [loadingQr, setLoadingQr] = useState(true);
  const [loadingQrCreate, setLoadingQrCrete] = useState(false);
  const [paymentLinkCopied, setPaymentLinkCopied] = useState(false);

  const [paymentHandle, setPaymentHandle] = useState(null);
  const [paymentQrCode, setPaymentQrCode] = useState(null);

  useEffect(() => {
    handleGetPaymentHandle();
    handleGetPaymentQrCode(setLoadingQr);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      formatPaymentHandle(state.paymentHandle || {});
      formatPaymentQrCode(state.paymentQrCode || {});
    } catch (err) {}
  }, [state]);

  const formatPaymentHandle = (obj) => {
    setPaymentHandle(obj.payment_handle || null);
  };

  const formatPaymentQrCode = (obj) => {
    setPaymentQrCode(obj.qr_code || null);
  };

  async function handleGetPaymentHandle(customLoading){
    await dispatch(GetPaymentHandle(companyId, customLoading ? customLoading : setLoading));
  };

  async function handleGetPaymentQrCode(customLoading){
    await dispatch(GetPaymentQrCode(companyId, customLoading ? customLoading : setLoading));
  };

  const handleGenerateQrCode = () => {
    dispatch(CreatePaymentQrCode(companyId, setLoadingQrCrete)).then(() => {
      handleGetPaymentQrCode(setLoadingQr);
    });
  };

  return (
    <Box> 
      <Box  
        className="paylink">
        <Typography
          variant='h6'>
          Receive payment link
        </Typography>
        <Typography>
          Share the link below or QR with the customers to enable them to make payments to you easily.
        </Typography>

        <Box
          className="flex__Column"
          gap={"30px"}>
          <Box
            className="flex__Column"
            gap={"10px"}>
            <Typography 
              textAlign={"start"}
              variant='subtitle1'>
              Payment link
            </Typography>
            {
              loading
              ?
              <Skeleton
                variant='rectangular'
                sx={{ height: "80px", borderRadius: "16px" }}
                />
              :
                paymentHandle
                ?
                <Box
                  className="flex__Column"
                  gap={"20px"}>
                  <Typography
                    variant='subtitle2'>
                    <Link
                      href={`${process.env.REACT_APP_CORE_WEB_URL}/makepayment/${paymentHandle}`}
                      target="_blank" rel="noopener noreferrer">
                      {`${process.env.REACT_APP_CORE_WEB_URL}/makepayment/${paymentHandle}`}
                    </Link>
                  </Typography>
                  <Box
                    className="flexCenterFERow">
                    <Button
                      disabled={paymentLinkCopied}
                      onClick={() => {
                        navigator.clipboard.writeText(`${process.env.REACT_APP_CORE_WEB_URL}/makepayment/${paymentHandle}`);
                        setPaymentLinkCopied(true);
                      }}>
                      {
                        paymentLinkCopied
                        ?
                        'Link copied'
                        :
                        'Copy link'
                      }
                    </Button>
                  </Box>
                </Box>
                :
                <Box
                  className="flexCenterCenterRow">
                  <NA
                    label={"No payment link found"}
                    />
                </Box>
            }
          </Box>

          <Typography 
            textAlign={"center"}
            color={"grey"}>
            OR
          </Typography>

          <Box
            className="flex__Column"
            gap={"10px"}>
            <Typography 
              textAlign={"start"}
              variant='subtitle1'>
              QR code
            </Typography>
            <Box
              className="flexCenterCenterColumn">
              {
                loadingQr
                ?
                  <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: "16px", 
                      width: "180px",
                      height: "180px"
                    }} 
                    />
                :
                  paymentQrCode
                  ?
                  <img
                    src={paymentQrCode || null}
                    alt='qr-code'
                    style={{
                      width: "180px",
                    }}
                  />
                  :
                  <LoadingButton
                    loading={loadingQrCreate}
                    onClick={handleGenerateQrCode}>
                    Generate QR code
                  </LoadingButton>
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentLinkPage