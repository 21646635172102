import * as actionTypes from "../../actionTypes";
import * as api from "../../../api/index";

export const GetPurposeCodes = (companyId, query, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.getPurposeCodes(companyId, query);
    res = data;
    statusCode = status;
    if (status === 200) {

    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode };
};

export const GetBeneficiaryRequirements = (companyId, query, setLoading) => async (dispatch) => {
    dispatch({ type: actionTypes.CLEAR_ERRORS });
    setLoading(true);
    let res, statusCode;
    try {
      const { data, status } = await api.getBeneficiaryRequirements(companyId, query);
      res = data;
      statusCode = status;
      if (status === 200) {
  
      }else{
        dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      }
      setLoading(false);
    } catch (error) {
      dispatch({ type: actionTypes.SET_CRASHES, payload: error });
      setLoading(false);
    }
    return { res, statusCode };
  };