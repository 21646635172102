import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button, 
  Chip, 
  CircularProgress, 
  IconButton, 
  Menu, 
  MenuItem, 
  Skeleton, 
  Tooltip, 
  Typography,
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import NA from '../../../../components/utils/NA';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { 
  CreateVerificationCompanyBankAccount,
  GetBankAccountTypes, 
  GetBanks,
  GetCompanyBankAccounts,
  ToggleDefaultCompanyBankAccount
} from '../../../../redux/actions/pay/companyBankAccountsActions';

import BankAccountModal from './BankAccountModal';

import global from "../../../../global.scss";
import "./styles.scss";

const PaymentMethodsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loading2, setLoading2] = useState(true);
  const [bankAccountsModalOpen, setBankAccountsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [companyBankAccountsList, setCompanyBankAccountsList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [companyBankAccountsTotal, setCompanyBankAccountsTotal] = useState(0);

  const [bankAccountsMenuOpen, setBankAccountsMenuOpen] = useState(false);
  const [bankAccountsMenuAnchor, setBankAccountsMenuAnchor] = useState(0);

  const [verifyId, setVerifyId] = useState(null);
  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    handleGetCompanyBankAccounts();
    dispatch(GetBankAccountTypes(companyId, 1, 50, setLoading2));
    dispatch(GetBanks(companyId, 1, 500, setLoading2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      formatCompanyBankAccountsList(state.companyBankAccountsList.records || []);
      setCompanyBankAccountsTotal(state.companyBankAccountsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatCompanyBankAccountsList = (list) => {
    setCompanyBankAccountsList(list);
  };

  const handleDefaultChange = (id) => {
    dispatch(ToggleDefaultCompanyBankAccount(companyId, update.id, setLoading)).then(() => {
      handleGetCompanyBankAccounts(setLoading);
    })
  };

  async function handleGetCompanyBankAccounts(customLoading){
    dispatch(GetCompanyBankAccounts(companyId, 1, 100, customLoading ? customLoading : setLoading));
  };

  const handleVerifyBankAccount = (id) => {
    setVerifyId(id);
    let obj = {
      "bank_account_id": id
    };
    dispatch(CreateVerificationCompanyBankAccount(companyId, obj, setVerifying)).then(() => {
      handleGetCompanyBankAccounts(setVerifying).then(() => {
        setVerifyId(null);
      });
    });
  };

  function getBankStatusVerifyComment(status){
    switch(status){
      case "valid": return "- Bank account is open and valid";
      case "closed": return "- Bank account is closed";
      case "invalid": return "- Account information is invalid";
      case "failed": return "- The verification request has failed";
      case "pending": return "";
      case null: return "";

      default: return "Invalid status";
    }
  };

  function getBankControlVerifyComment(status){
    switch(status){
      case "valid": return "";
      case "pending": return "";
      case "canceled": return "- The maximum number of attempts has been reached or it has been 5 days since the request was sent to the receiving bank";
      case null: return "";

      default: return "Invalid status";
    }
  };

  return (
    <Box>
      
      <BankAccountModal
        open={bankAccountsModalOpen}
        setOpen={setBankAccountsModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetCompanyBankAccounts}
        />

      <Box 
        className="pmtmethod__top">
        <Box
          className="flexCenterSBRow">
          <Typography
            variant='h6'>
            Bank Account
          </Typography>
          <Button
            variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1
              });
              setModalTitle("Add Bank account")
              setBankAccountsModalOpen(!bankAccountsModalOpen);
            }}>
            Add Bank account
          </Button>
        </Box>

        {
          loading 
          ?
          <Skeleton 
            variant='rectangular'
            sx={{ height: "90px", borderRadius: "16px" }}
            />
          :
          companyBankAccountsList.length === 0
          ?
          <Box
            className="flexCenterCenterRow"
            height={"90px"}>
            <NA 
              label={"No bank account added"}/>
          </Box>
          :
            companyBankAccountsList.map((bank, bankIndex) => (
              <Box
                className="pmtmethod__top-card"
                key={bankIndex}>
                <Box
                  className="flexCenter_Row"
                  gap={"20px"}>
                  <AccountBalanceOutlinedIcon 
                    fontSize="large"
                    />
                  <Box
                    sx={{ marginRight: "auto" }}>
                    <Typography variant='subtitle2'>
                      {bank?.account_type_id?.type || <NA />}
                      &nbsp;-&nbsp;
                      {bank.account_number?.slice(-5) || <NA />}</Typography>
                    {/* <Typography color={"grey"}>Free</Typography> */}
                    <Box
                      className="flexCenter_Row"
                      gap={"10px"}>
                      {
                        bank.is_default === true
                        &&
                        <Chip
                          color='warning'
                          size='small'
                          label={'Primary'} 
                          />
                      }
                      {
                        bank.verification_pending === false && bank.verified === false
                        &&
                        <Chip
                          color='error'
                          size='small'
                          label={'Unverified'} 
                          />
                      }
                    </Box>
                  </Box>
                  {
                    bank.verification_pending === true
                    ?
                    <Tooltip>
                      <Chip
                        color='warning'
                        label={"Verification pending"} 
                        // clickable={true}
                        // onClick={() => {
                          
                        // }}
                        />
                    </Tooltip>
                    :
                    <Box
                      className="flexCenter_Row"
                      gap={"5px"}>
                      {
                        bank.verified === false
                        &&
                          (bank.id === verifyId && verifying
                          ?
                          <CircularProgress 
                            size={28}
                            />
                          :
                          <Chip
                            color='info'
                            label={"Verify"} 
                            clickable={true}
                            onClick={() => {
                              handleVerifyBankAccount(bank.id);
                            }}
                            />)
                      }
                      <IconButton
                        onClick={(e) => {
                          setBankAccountsMenuAnchor(e.currentTarget);
                          setBankAccountsMenuOpen(true);
                          // setDeleteId(row.id);
                          setUpdate(bank);
                          setModalTitle(bank.account_number || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </Box>
                  }
                </Box>
                {
                  bank.verification_pending === true
                  &&
                  <Box
                    pt={"20px"}
                    pl={"55px"}
                    className="flex__Column"
                    gap="10px">
                    <Box
                      className="flexCenter_Row"
                      gap={"10px"}>
                      {
                        bank.verification_status === "valid"
                        ?
                        <CheckCircleOutlinedIcon 
                          color='success'
                          fontSize='small'
                          />
                        :
                          ["closed", "invalid", "failed"].includes(bank.verification_status)
                          ?
                          <CancelOutlinedIcon 
                            color='error'
                            fontSize='small'
                            />
                          :
                            bank.verification_status === "pending" || bank.verification_status === null
                            ?
                            <CircularProgress 
                              size={17}
                              />
                            :
                            <Box 
                              width={"20px"}
                              height={"20px"} />
                      }
                      <Typography
                        fontWeight={500}>
                        Account status
                      </Typography>
                      <Typography
                        ml={"-5px"}>
                        {getBankStatusVerifyComment(bank.verification_status)}
                      </Typography>
                    </Box>
                    <Box
                      className="flexFS_Row"
                      gap={"10px"}>
                      {
                        bank.verification_status === "valid"
                        ?
                          bank.control_status === "valid"
                          ?
                          <CheckCircleOutlinedIcon 
                            color='success'
                            fontSize='small'
                            />
                          :
                            bank.control_status === "canceled"
                            ?
                            <CancelOutlinedIcon 
                              color='error'
                              fontSize='small'
                              />
                            :
                              bank.control_status_applied === false
                              ?
                              <Box 
                                width={"20px"}
                                height={"20px"} />
                              :
                                bank.control_status === "pending" || bank.control_status === null
                                ?
                                <CircularProgress 
                                  size={17}
                                  sx={{ margin: "1.5px" }}
                                  />
                                :
                                <Box 
                                  width={"20px"}
                                  height={"20px"} />
                          :
                          <Box 
                            width={"20px"}
                            height={"20px"} />
                      }
                      <Typography
                        fontWeight={500}
                        color={bank.verification_status === "valid" ? "black" : "grey"}>
                        Account access
                      </Typography>
                      <Typography
                        ml={"-5px"}>
                        {getBankControlVerifyComment(bank.control_status)}
                      </Typography>
                      {
                        (bank.control_status_applied === false 
                          && bank.verification_status === "valid" 
                          && bank.control_status !== "canceled")
                        &&
                        <Tooltip>
                          <Chip
                            color='info'
                            label={"Submit code"} 
                            clickable={true}
                            onClick={() => {
                              setUpdate({
                                defaultMode: 2, 
                                bank_account_id: bank.id
                              });
                              setModalTitle("Enter statement code");
                              setBankAccountsModalOpen(!bankAccountsModalOpen);
                            }}
                            />
                        </Tooltip>
                      }
                    </Box>
                  </Box>
                }
              </Box>
            ))
        }
      </Box>

      {/* <Box
        className="pmtmethod__bottom">
        <Box>
          <Typography
            variant='h6'>
            Card
          </Typography>
          <Typography color={"grey"}>2.9% fee</Typography>
        </Box>
        <Typography textAlign={"center"}>Defer payments and earn rewards.</Typography>

        <Link>+ Add card</Link>
      </Box> */}


      <Menu
        open={bankAccountsMenuOpen}
        anchorEl={bankAccountsMenuAnchor}
        onClose={() => setBankAccountsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setBankAccountsMenuOpen(false);
            setBankAccountsModalOpen(!bankAccountsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem> */}
        {
          update?.is_default === false 
          &&
          <MenuItem
            onClick={() => {
              setBankAccountsMenuOpen(false);
              handleDefaultChange();
            }}>
            <Box
              className="flexCenter_Row"
              gap={"10px"}>
              <TaskAltOutlinedIcon />
              Make Primary
            </Box>
          </MenuItem>
        }
      </Menu>

    </Box>
  )
}

export default PaymentMethodsPage;