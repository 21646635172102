import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Chip, 
  Skeleton, 
  Typography,

} from '@mui/material';

import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import NA from '../../../../components/utils/NA';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

import { 
  GetCompanyBankAccounts 
} from '../../../../redux/actions/pay/companyBankAccountsActions';

import "./styles.scss";

const ReceivingMethodsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);

  const [companyBankAccounts, setCompanyBankAccounts] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [companyBankAccountsTotal, setCompanyBankAccountsTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    // eslint-disable-next-line no-unused-vars
    handleChangePage,
    // eslint-disable-next-line no-unused-vars
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  useEffect(() => {
    dispatch(GetCompanyBankAccounts(companyId, page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try {
      formatCompanyBankAccounts(state.companyBankAccountsList.records || []);
      setCompanyBankAccountsTotal(state.companyBankAccountsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatCompanyBankAccounts = (list) => {
    setCompanyBankAccounts(list.find(i => i.is_default === true) || null);
  };

  return (
    <Box>
      <Box 
        className="recemethod__top">
        <Box>
          <Typography
            variant='h6'>
            Bank Account
          </Typography>
        </Box>

        {
          loading 
          ?
          <Skeleton 
            variant='rectangular'
            sx={{ height: "90px", borderRadius: "16px" }}
            />
          :
          companyBankAccounts === null
          ?
          <Box
            className="flexCenterCenterRow"
            height={"90px"}>
            <NA
              label={"No bank account added"}/>
          </Box>
          :
            <Box
              className="pmtmethod__top-card flexCenter_Row"
              gap={"20px"}>
              <AccountBalanceOutlinedIcon 
                fontSize='large'
                />
              <Box
                sx={{ marginRight: "auto" }}>
                <Typography variant='subtitle2'>
                  {companyBankAccounts?.account_type_id?.type || <NA />}
                  &nbsp;-&nbsp;
                  {companyBankAccounts.account_number?.slice(-5) || <NA />}</Typography>
                {/* <Typography color={"grey"}>Free</Typography> */}
                {
                  companyBankAccounts.is_default === true
                  &&
                  <Chip
                    color='warning'
                    size='small'
                    label={'Primary'} 
                    />
                }
              </Box>
            </Box>
        }

        {/* <Box
          className="recemethod__top-card flexCenter_Row"
          gap={"20px"}>
          <img
            src={AMEXLogo}
            alt="amex-logo"
            style={{
              width: "70px",
            }}
            />
          <Box
            sx={{ marginRight: "auto" }}>
            <Typography variant='subtitle2'>Business checking x1356</Typography>
          </Box>
          <IconButton
            onClick={(e) => {
            }}>
            <MoreVertIcon sx={{ color: global['secondary_light']}}/>
          </IconButton>
        </Box> */}
      </Box>

      {/* <Box
        className="recemethod__bottom">
        <Box>
          <Typography
            variant='h6'>
            Card
          </Typography>
          <Typography color={"grey"}>Fee payment</Typography>
        </Box>
        <Typography textAlign={"center"}>
          When customers pay my business with card, who pays 2.9% card fee?
        </Typography>

        <Box  
          sx={{ margin: "auto" }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="receiving-method-card-radio-label"
              defaultValue="female"
              name="radio-buttons-group"
              onChange={() =>  setEnableSave(true)}
            >
              <FormControlLabel 
                value="female" 
                sx={{ marginBottom: "20px" }}
                control={<Radio />} 
                label={
                  <Box>
                    <Typography
                      variant='subtitle1'>
                      Customer pays
                    </Typography>
                    <Typography>
                      Fee gets added to your customer payment.
                    </Typography>
                  </Box>
                } 
                />
              <FormControlLabel 
                value="male" 
                control={<Radio />} 
                label={
                  <Box>
                    <Typography
                      variant='subtitle1'>
                      My business pays
                    </Typography>
                    <Typography>
                      Fee will be deducted from your bank account.
                    </Typography>
                  </Box>
                } 
                />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box  
          className="flexCenterFERow">
          <Button
            disabled={!enableSave}
            onClick={() => setEnableSave(false)}>
            Save
          </Button>
        </Box>
      </Box> */}
    </Box>
  )
}

export default ReceivingMethodsPage;