import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  Box,
  Button,
  Container, 
  TextField, 
  Typography
} from '@mui/material';

import Logo from "../../assets/icons/logo-blue-dark2.svg";
import LoadingButton from '../../components/buttons/Button';

import style from "../../global.scss";
import "./styles.scss";

const Data = {
  userName: "",
  password: ""
}

const ForgotPasswordPage = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);

  useEffect(() => {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleLogin = () => {
    console.log(formData);
  }

  return (
    <Box >
      <Box className='auth__top'>
        <Container maxWidth="lg">
          <Box className='flexCenterSBRow'>
            <img
              src={Logo}
              alt="lusid-logo"
              style={{ width: "140px" }}
              />
            <Typography variant='h5' sx={{ color: style["secondary"] }}>
              {"Business"}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box className="auth flexCenterCenterRow">
          <Box className="auth__content flexCenterCenterRow" 
            p={"25px"}>
            <Box className="flex_CenterColumn" gap={"20px"} width={"360px"}>
              <Typography
                variant='h6'
                textAlign={"center"}>
                Forgot Password?
              </Typography>
              <Typography
                variant='body2'
                textAlign={"center"}>
                Enter your email to receive password reset code.
              </Typography>
              <TextField 
                variant="outlined"
                label="Email"
                name="userName"
                fullWidth={true}
                placeholder='Enter your email'
                value={formData.userName}
                onChange={handleChange}
                />
              <LoadingButton
                variant='contained' 
                loading={loading}
                onClick={handleLogin}>
                Send Reset Code
              </LoadingButton>
              <Button
                variant='text'
                onClick={() => navigate("/auth")}>
                Login
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default ForgotPasswordPage;