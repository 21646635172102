import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button,
  Chip,
  CircularProgress,
  IconButton, 
  Link, 
  Menu, 
  MenuItem,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow,  
  TableSortLabel,  
  Tooltip,  
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../components/textFields/SearchTextField';
import useCurrencyService from '../../../components/hooks/useCurrencyService';
import BillsStatusChip from '../../../components/utils/BillsStatusChip';
import DateFormatter from '../../../components/utils/DateFormatter';
import TableLoading from '../../../components/loaders/TableLoading';
import NameAvatar from '../../../components/avatars/Avatar';
import NATable from '../../../components/utils/NATable';
import NA from '../../../components/utils/NA';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LanguageIcon from '@mui/icons-material/Language';
import LoopIcon from '@mui/icons-material/Loop';

import { 
  GetVendors 
} from '../../../redux/actions/pay/vendorsActions';
import {  
  DeleteBill,
  GetBills,
  GetPaymentFrequencies 
} from '../../../redux/actions/pay/billsActions';
import { 
  GetCompanyBankAccounts 
} from '../../../redux/actions/pay/companyBankAccountsActions';

import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import CreateInternationalPaymentModal from '../paymentsPage/CreateInternationalPaymentModal';
import CreatePaymentModal from '../paymentsPage/CreatePaymentModal';
import CreateBillModal from './CreateBillModal';

import { 
  OPEN_IMAGE_VIEWER, 
  SET_IMAGES_URL 
} from '../../../redux/actionTypes';

import global from "../../../global.scss";
import "./styles.scss";

const BillsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const { handleShowAmount } = useCurrencyService();
  const [loading, setLoading] = useState(true); 
  // eslint-disable-next-line no-unused-vars
  const [loading2, setLoading2] = useState(true); 
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [statusFilter, setStatusFilter] = useState("Created");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [createInternationalPaymentModalOpen, setCreateInternationalPaymentModalOpen] = useState(false);
  const [createPaymentModalOpen, setCreatePaymentModalOpen] = useState(false);
  const [createBillModalOpen, setCreateBillModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);
  
  const [billsList, setBillsList] = useState([]);
  const [billsTotal, setBillsTotal] = useState(0);
  
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  
  const [billMenuOpen, setBillMenuOpen] = useState(false);
  const [billMenuAnchor, setBillMenuAnchor] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Vendor",
      sort: "ref_vendor_id",
    },
    {
      label: "Bill",
      sort: "invoice_number",
    },
    {
      label: "Creation date",
      sort: "created_at",
    },
    {
      label: "Due date",
      sort: "due_date",
    },
    {
      label: "Amount",
      sort: "total_amount",
      align: "right"
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    dispatch(GetVendors(companyId, 1, 1000, "", setLoading2));
    dispatch(GetPaymentFrequencies(companyId, setLoading2));
    dispatch(GetCompanyBankAccounts(companyId, 1, 500, setLoading2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetBills();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection, statusFilter]);

  useEffect(() => {
    try {
      console.log(state);
      formatBillsList(state.billsList.records || []);
      setBillsTotal(state.billsList.total || 0);
    } catch (err) {}
  }, [state]);

  // -------------------- list formatter --------------------
  const formatBillsList = (list) => {
    setBillsList(list);
  };

  async function handleGetBills(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    if(statusFilter) query += `&status__in=${statusFilter}`;
    await dispatch(GetBills(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteBill(companyId, deleteId, setDeleting)).then(() => {
      handleGetBills(setDeleting).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <ConfirmationModal 
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete}
        />

      <CreateBillModal
        open={createBillModalOpen}
        setOpen={setCreateBillModalOpen}
        title={modalTitle}
        onMethod={(e) => {
          setUpdate(e);
          setCreateBillModalOpen(false);
          setCreatePaymentModalOpen(true);
        }}
        update={update}
        handleGet={handleGetBills}
        />

      <CreateInternationalPaymentModal 
        open={createInternationalPaymentModalOpen}
        setOpen={setCreateInternationalPaymentModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetBills}
        />

      <CreatePaymentModal 
        open={createPaymentModalOpen}
        setOpen={setCreatePaymentModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetBills}
        />      

      <Box 
        className="flexCenterSBRow" 
        gap="15px"
        mb={"10px"}>
        <Box  
          className="flexCenter_Row"
          gap="15px">
          <Chip
            variant='outlined'
            color={statusFilter === "Created" ? 'success' : 'default'}
            label="Created"
            onClick={() => {
              setStatusFilter("Created");
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Scheduled" ? 'warning' : 'default'}
            label="Archived"
            onClick={() => {
              setStatusFilter("Scheduled");
            }}
            />
        </Box>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
          <Button
            variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1
              });
              setModalTitle("Create Bill");
              setCreateBillModalOpen(!createBillModalOpen);
            }}>
            Add Bill
          </Button>
        </Box>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          <Table className="table">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                billsList.length === 0
                ?
                <NATable
                  label={"No bill found"}
                  />
                :
                billsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      {
                        row.ref_vendor_id
                        ?
                        <Box
                          className="flexCenter_Row"
                          gap={"10px"}>
                          <NameAvatar
                            color={row.ref_vendor_id?.color || null}>
                            {row.ref_vendor_id?.name || "NA"}
                          </NameAvatar>
                          <Typography>
                            {row.ref_vendor_id?.name || "NA"}
                          </Typography>
                          {
                            row.ref_vendor_id?.is_international === true
                            &&
                            <Tooltip
                              title={"International vendor"}>
                              <LanguageIcon 
                                sx={{ color: "lightgray" }}
                                fontSize='small'/>
                            </Tooltip>
                          }
                        </Box>
                        :
                        <NA 
                          label={"No supplier found"} />
                      }
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography variant='body2'>
                          {(row.invoice_number && row.invoice_number !== "NA")
                            ? <Typography variant='body2'>#{row.invoice_number}</Typography>
                            : <i style={{ color: "grey" }}>No invoice #</i>}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{row.created_at && DateFormatter(row.created_at)}</TableCell>
                    <TableCell>
                      <Box
                        className="flexCenter_Row"
                        gap={"20px"}>
                        {row.due_date && DateFormatter(row.due_date)}
                        {
                          statusFilter === "Created"
                          &&
                          <BillsStatusChip 
                            dueDate={row.due_date}/>
                        }
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ pt: 0.5, pb: 0.5 }}
                      align='right'>
                      {
                        row.currency !== row.base_currency
                        ?
                        <Box>
                          <Box>
                            <Typography variant='body2' fontWeight={600}>
                              {handleShowAmount(row.base_currency, row.base_amount)}
                            </Typography>
                          </Box>
                          <Typography variant='caption' fontWeight={500} color={"grey"}>
                            {handleShowAmount(row.currency, row.total_amount)}
                          </Typography>
                        </Box>
                        :
                        <Box>
                          <Typography variant='body2' fontWeight={600}>
                            {handleShowAmount(row.base_currency, row.base_amount)}
                          </Typography>
                        </Box>
                      }
                      {
                        row.is_recurring === true
                        && 
                        <Box 
                          className="flexCenterFERow"
                          color={"grey"}>
                          <LoopIcon fontSize='small'/>
                          <Typography variant='caption' color={"grey"}>
                            Recurring
                          </Typography>
                        </Box>
                      }
                    </TableCell>
                    <TableCell>
                      <Box 
                        className="flexCenterFERow" 
                        gap={"20px"}>
                        {
                          statusFilter === "Scheduled"
                          ?
                          <Link 
                            onClick={() => {
                              // setUpdate({ 
                              //   ...row,
                              //   defaultMode: 2
                              // });
                              // setCreatePaymentModalOpen(!createPaymentModalOpen);
                            }}>
                            View payment
                          </Link>
                          :
                          <Link 
                            onClick={() => {
                              setUpdate({ 
                                ...row,
                                defaultMode: 2
                              });
                              if (row.ref_vendor_id?.is_international === true){
                                setCreateInternationalPaymentModalOpen(!createInternationalPaymentModalOpen);
                              } else {
                                setCreatePaymentModalOpen(!createPaymentModalOpen);
                              }
                            }}>
                            Pay
                          </Link>
                        }
                        <IconButton
                          disabled={row?.bill_attachments.length === 0}
                          onClick={() => {
                            setUpdate(row?.bill_attachments.map(i => { return i.attachment_path }))
                            dispatch({ type: OPEN_IMAGE_VIEWER });
                            dispatch({ type: SET_IMAGES_URL, payload: row?.bill_attachments.map(i => { return i.attachment_path }) });
                          }}>
                          <ReceiptLongOutlinedIcon />
                        </IconButton>
                        {
                          row.bill_id === deleteId && deleting
                          ?
                          <CircularProgress size={26} sx={{ margin: "7px" }}/>
                          :
                          <IconButton
                            onClick={(e) => {
                              setBillMenuAnchor(e.currentTarget);
                              setBillMenuOpen(true);
                              setDeleteId(row.bill_id);
                              setUpdate(row);
                              setModalTitle("Delete bill for - " + row.ref_vendor_id?.name || "");
                            }}>
                            <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                          </IconButton>
                        }
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={billsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={billMenuOpen}
        anchorEl={billMenuAnchor}
        onClose={() => setBillMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {

          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <VisibilityOutlinedIcon />
            View
          </Box>
        </MenuItem>
        {
          update && update.status === "Created"
          &&
          <>
            <MenuItem
              onClick={() => {
                setModalTitle("Edit bill for - " + update.ref_vendor_id?.name || "");
                setUpdate({
                  ...update,
                  defaultMode: 2
                });
                setBillMenuOpen(false);
                setCreateBillModalOpen(!createBillModalOpen);
              }}>
              <Box
                className="flexCenter_Row"
                gap={"10px"}>
                <EditOutlinedIcon />
                Edit
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setBillMenuOpen(false);
                setDeleteConfirmationModalOpen(true);
              }}>
              <Box
                className="flexCenter_Row"
                gap={"10px"}>
                <DeleteOutlineOutlinedIcon />
                Delete
              </Box>
            </MenuItem>
          </>
        }
      </Menu>
    </Box>
  )
}

export default BillsPage;