import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../../components/topBar/TopBar';
import TabsPanel from '../../../components/tabs/TabsPanel';
import OnboardingPage from './onboardingPage/OnboardingPage';
import WalletPage from './walletPage/WalletPage';

const InternationalIndex = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(localStorage.getItem("internationalTabSET"));

  useEffect(() => {
    if("child" in params){
      switch (params.child) {
        case "onboarding": 
          localStorage.setItem("internationalTabSET", "1"); 
          setCurrentTab("1"); 
          break;
        case "wallet": 
          localStorage.setItem("internationalTabSET", "2"); 
          setCurrentTab("2"); 
          break;

        default: {
          localStorage.setItem("internationalTabSET", "1");
          setCurrentTab("1");
          navigate("/settings/international");
        }
      }
      navigate("/settings/international")
    }else{
      if(!localStorage.getItem("internationalTabSET")){
        localStorage.setItem("internationalTabSET", "1");
        setCurrentTab("1");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TopBar
        title="Settings - International"
        />
      
      <Box className="flexCenterSBRow" mb={"10px"}>
        <TabsPanel
          value={currentTab+""}
          handleChange={(e, value) => {
            localStorage.setItem("internationalTabSET", value);
            setCurrentTab(value);
          }}
          links={[
            { value: "1", label: "Onboarding", path: "/onboarding" },
            { value: "2", label: "Wallet", path: "/wallet" },
          ]}
          />
      </Box>
      {
        currentTab === "1"
        ?
          <OnboardingPage />
          :
            currentTab === "2"
            ?
              <WalletPage />
              :
                <></>
      }
    </Box>
  )
}

export default InternationalIndex;