import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { 
  Box,
} from '@mui/material';

import SideBarLogo from '../utils/SideBarLogo';
import ConfirmationModal from '../modals/ConfirmationModal';
import TopNavigation from './TopNavigation';
import SideNavigation from './SideNavigation';

import { LOG_OUT } from '../../redux/actionTypes';

import "./styles.scss";

const Navigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [isNavExpanded, setIsNavExpanded] = useState(JSON.parse(localStorage.getItem("navExpanded")));

  useEffect(() => {
    var route = location.pathname.split("/").slice(1);    
    route = route.join("/")

    switch(route){
      case "": return setCurrentPage(0);
      case "pay": return setCurrentPage(1);
      case "get-paid": return setCurrentPage(2);
      case "expense": return setCurrentPage(3);
      // case "wallet": return setCurrentPage(4);

      // admin 
      case "admin":  return setCurrentPage(0);
      case "admin/employees":  return setCurrentPage(1);
      case "admin/branch":  return setCurrentPage(2);

      // settings
      case "settings": return setCurrentPage(0);
      case "settings/pay": return setCurrentPage(1);
      case "settings/receive": return setCurrentPage(2);
      case "settings/expense": return setCurrentPage(3);
      case "settings/accounting": return setCurrentPage(4);
      case "settings/international": return setCurrentPage(5);
      // case "settings/wallet": return setCurrentPage(4);
      case "settings/billing": return setCurrentPage(6);
  
      default: return setCurrentPage(0);
    }

  }, [currentPage, location.pathname]);

  // ======================= Log out handler ============================
  const handleLogout = () => {
    dispatch({ type: LOG_OUT });
  }

  return (
    <Box className="nav">
      <ConfirmationModal 
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        title={"Log out"}
        onConfirm={handleLogout}
        />

      <Box display={"flex"}
        sx={{ 
          height: "100vh" }}>
        {
          location.pathname !== "/add-bank-account" && location.pathname !== "/add-subscription"
          &&
          <SideBarLogo 
            isNavExpanded={isNavExpanded} />
        }
        {
          (location.pathname !== "/dashboard" && location.pathname !== "/profile" &&
            location.pathname !== "/add-bank-account" && location.pathname !== "/add-subscription"
          )
          &&
          <SideNavigation
            currentPage={currentPage}
            isNavExpanded={isNavExpanded}
            setIsNavExpanded={setIsNavExpanded} />
        }
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            height: "calc(100vh - 0px)",
            width: "100%"
          }}>
          {
            location.pathname !== "/add-bank-account" && location.pathname !== "/add-subscription"
            &&
            <TopNavigation />
          }
          <Box 
            padding={"10px 30px 0px 30px"}
            sx={{
            height: "100%",
            backgroundColor: "white",
            margin: "0px 10px 10px 10px",
            borderRadius: "16px",
            overflow: "auto",
            // boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
            }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Navigation;