import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Box, 
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  Typography
} from '@mui/material';

import SearchTextField from '../../../components/textFields/SearchTextField';
import CompanyAvatar from '../../../components/avatars/CompanyAvatar';
import TopBar from '../../../components/topBar/TopBar';

import { 
  GetCompanies 
} from '../../../redux/actions/dashboard/companiesActions';
import { 
  GetCompanyInfo 
} from '../../../redux/actions/dashboard/companyRoleActions';

import CompaniesModal from './CompaniesModal';

import { 
  CLEAR_COMPANY_ROLE_DASHBOARD,
  LOG_OUT,
  SELECT_COMPANY 
} from '../../../redux/actionTypes';

import global from "../../../global.scss";
import "./styles.scss";

const CompaniesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const state = useSelector(state => state.dashboard);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [companiesModalOpen, setCompaniesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);
  
  const [companiesList, setCompaniesList] = useState([]);

  const [loadingRole, setLoadingRole] = useState(false);
  const [companyId, setCompanyId] = useState(null);

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    dispatch(GetCompanies(1, 500, query, setLoading));


    console.log(location)

    let queries = location.search.slice(1).split("&");
    let firstQuery = queries[0].split("=");

    console.log(queries)
    console.log(firstQuery)

    if("addCompany" === firstQuery[0] && firstQuery[1] === "true"){
      setUpdate({ defaultMode: 1 });
      setCompaniesModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  
  useEffect(() => {
    try { 
      console.log(state)
      formatCompaniesList(state.companiesList.records || []);
    } catch (err) {}
  }, [state]);

  const formatCompaniesList = (list) => {
    setCompaniesList(list);
  };

  const handleSelectCompany = async (company) => {
    setCompanyId(company.Id);

    try {
      await dispatch({ type: CLEAR_COMPANY_ROLE_DASHBOARD });
      await dispatch(GetCompanyInfo(company.Id, setLoadingRole)).then(async ({ res, statusCode }) => {
        await dispatch({ type: SELECT_COMPANY, payload: company });
        if(statusCode === 200){
          if(res.data.company_bank_account_exists === false){
            navigate("/add-bank-account");
          } else if (res.data.company_has_subs === false){
            navigate("/add-subscription");
          } else {
            navigate("/");
          }
        } else {

        }
      });
      setCompanyId(null);


    } catch (err){
      dispatch({ type: LOG_OUT });
    }
  };

  return (
    <Box> 

      <CompaniesModal
        open={companiesModalOpen}
        setOpen={setCompaniesModalOpen}
        title={modalTitle}
        update={update}
        />

      <Container
        maxWidth="md">

        <TopBar
          title={"Companies"}
          />

        <Box 
          className="flexCenterSBRow" 
          mb={"10px"}>
          <Box
            className="flexCenter_Row" 
            gap={"10px"}>
            <SearchTextField
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              />
          </Box>

          <Button
            variant='contained'
            onClick={() => {
              setUpdate({ defaultMode: 1 });
              setModalTitle("Details for new company");
              setCompaniesModalOpen(!companiesModalOpen);
            }}>
            Create New Company
          </Button>
        </Box>

        {
          loading
          ?
          <Grid container spacing={2} pt={"20px"}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Skeleton variant="rectangular" 
                sx={{ height: "66px", borderRadius: "20px" }} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Skeleton variant="rectangular" 
                sx={{ height: "66px", borderRadius: "20px" }} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Skeleton variant="rectangular" 
                sx={{ height: "66px", borderRadius: "20px" }} />
            </Grid>
          </Grid>
          :
            companiesList.length === 0
            ?
            <Box
              minHeight={"250px"} 
              className="flexCenterCenterRow">
              <Typography
                variant='subtitle2'
                color={"grey"}>
                No company found
              </Typography>
            </Box>
            :
            <Grid 
              container
              spacing={2}
              pt={"20px"}
              mb={"20px"}>
              {
                companiesList.map((item, index) => (
                <Grid
                  item
                  lg={6} md={6} sm={6} xs={12}
                  key={index}>
                  <Box
                    className="company flex__Column"
                    gap="15px"
                    onClick={
                      loadingRole
                      ? () => {}
                      : () => handleSelectCompany(item)
                    }>
                    <Box
                      className="flexCenter_Row"
                      gap={"15px"}>
                      {
                        loadingRole && companyId === item.Id
                        ? 
                          <CircularProgress 
                            size={34}
                            />
                        :
                          item.LogoUrl
                          ?
                          <Box
                            sx={{ 
                              width: "36px", 
                              height: "36px", 
                              borderRadius: "8px" ,
                              overflow: "hidden",
                              border: "solid 1px #f1f1f1" }}>
                              <img 
                                 src={item.LogoUrl}
                                alt='company-logo'
                                style={{
                                  width: "36px", 
                                  height: "36px", 
                                  objectFit: "contain"
                                }}
                                />
                          </Box>
                          :
                          <CompanyAvatar 
                            color={item.Color}>
                            {item.LegalName}
                          </CompanyAvatar>
                      }

                      <Typography
                        variant='h6'
                        fontWeight={500}>
                        {item.LegalName}
                      </Typography>
                    </Box>
                    {/* <Typography
                      variant='subtitle2'
                      color={"grey"}
                      fontWeight={400}>
                      {item.LegalName}
                      </Typography> */}
                    <Box
                      className="flexCenterFERow">
                      <Chip
                        label={item.Role}
                        sx={{ 
                          color: item.Role === "Owner"  ? global["purple"] : global["secondary"],
                          backgroundColor: item.Role === "Owner" ? global["purple_light"] : global["primary_light"]
                        }}
                        />
                    </Box>

                  </Box>
                </Grid>
              ))
              }
            </Grid>

        }
      
      </Container>

    </Box>
  )
}

export default CompaniesPage