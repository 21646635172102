import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../../components/textFields/SearchTextField';
import TableLoading from '../../../../components/loaders/TableLoading';
import NA from '../../../../components/utils/NA';

import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { 
  DeleteEmployee,
  GetEmployees, 
  GetEmployeesAllowedForInternationalPayments
} from '../../../../redux/actions/main/EmployeesActions';
import { 
  GetCompanyAllowedForInternationalPayments 
} from '../../../../redux/actions/dashboard/companiesActions';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import UsersModal from './UsersModal';

import global from "../../../../global.scss";
import "./styles.scss";

const UsersPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.main);
  const dashboard = useSelector(state => state.dashboard);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [employeesModalOpen, setEmployeesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);
  
  const [employeesList, setEmployeesList] = useState([]);
  const [employeesTotal, setEmployeesTotal] = useState(0);
  
  const [employeesMenuOpen, setEmployeesMenuOpen] = useState(false);
  const [employeesMenuAnchor, setEmployeesMenuAnchor] = useState(null);
  
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  
  const [allowedForInterPmts, setAllowedForInterPmts] = useState(false);
  
  const [checkingAllowed, setCheckingAllowed] = useState(false);
  const [allowedList, setAllowedList] = useState(null);
  
  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  useEffect(() => {
    handleGetEmployees();
    // dispatch(GetBranches(companyId, 1, 100, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, page, rowsPerPage]);

  useEffect(() => {
    try {
      formatEmployeesList(state.employeesList.records || []);
      setEmployeesTotal(state.employeesList.total || 0);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    try {
      console.log(dashboard)
      setAllowedForInterPmts(dashboard.allowedForInternationalPayments)
    } catch (err) {}
  }, [dashboard]);

  // ------------------ list formatter ------------------
  const formatEmployeesList = (list) => {
    setEmployeesList(list);

    // check for each employee
    handleGetEmployeesAllowedForInternationalPayments(list);
  };

  async function handleGetEmployees(){
    await dispatch(GetCompanyAllowedForInternationalPayments(companyId, setLoading));
    dispatch(GetEmployees(companyId, page+1, rowsPerPage, setLoading));
  };

  async function handleGetEmployeesAllowedForInternationalPayments(list){
    let obj = {
      "employees_list": list.map(i => {
        return i.guid;
      })
    };
    dispatch(GetEmployeesAllowedForInternationalPayments(companyId, obj, setCheckingAllowed)).then(({ res, statusCode }) => {
      if(statusCode === 200){
        console.log(res)
        setAllowedList(res.data);
      }
    });
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteEmployee(companyId, deleteId, setDeleting)).then(() => {
      dispatch(GetEmployees(companyId, page+1, rowsPerPage, setDeleting)).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <UsersModal 
        open={employeesModalOpen}
        setOpen={setEmployeesModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetEmployees}
        />

      <ConfirmationModal 
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete}
        />

      <Box 
        className="flexCenterSBRow" 
        mb={"10px"}>
        <Box
          className="flexCenter_Row" 
          gap={"10px"}>
          <SearchTextField />
        </Box>
        <Button variant='contained'
          onClick={() => {
            setUpdate({
              defaultMode: 1
            });
            setModalTitle("Create user");
            setEmployeesModalOpen(!employeesModalOpen);
          }}>
          Add User
        </Button>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          employeesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No user found</Typography>
          </Box>
          :
          <Table className='user'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                {/* <TableCell>Mobile</TableCell> */}
                <TableCell>Role</TableCell>
                <TableCell>Location</TableCell>
                {
                  allowedForInterPmts === true
                  &&
                  <TableCell
                    align='center'>International payments</TableCell>
                }
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                employeesList.map((row, index) => (
                  <TableRow 
                    key={index}
                    sx={{ backgroundColor: row.employee_status !== "active" && "#faf1f1" }}>
                    <TableCell>
                      <Box className="flexCenter_Row" gap={"15px"}>
                        {row?.user?.first_name || ""}&nbsp;
                        {row?.user?.last_name  || ""}
                        {
                          row?.external
                          ?
                          <Chip
                            size='small'
                            // color='warning'
                            sx={{ backgroundColor: "#f5d742" }}
                            label={"External"}
                            />
                          :
                          <></>
                        }
                      </Box>
                    </TableCell>
                    <TableCell>{row?.user?.primary_email || <NA/>}</TableCell>
                    {/* <TableCell>{row?.user?.primary_mobile || <NA/>}</TableCell> */}
                    <TableCell>
                      <Chip
                        size='small'
                        label={row?.employee_role || <NA/>} 
                        sx={{ 
                          color: row?.employee_role === "Admin" ? global["purple"] : global["secondary"],
                          backgroundColor: row?.employee_role === "Admin" ? global["purple_light"] : global["primary_light"]
                        }}
                        />
                    </TableCell>
                    <TableCell>{row?.branch?.display_name || <NA/>}</TableCell>
                    {
                      allowedForInterPmts === true
                      &&
                      <TableCell
                        align='center'>
                        {
                          checkingAllowed
                          ?
                            <Box
                              className="flexCenterCenterRow">
                              <Skeleton 
                                variant="rectangular"
                                sx={{ width: "24px", height: "24px", borderRadius: "8px" }}
                                />
                            </Box>
                          :
                            allowedList
                            && 
                            allowedList[index]  
                              ?
                                <Tooltip
                                  title="Allowed">
                                  <CheckCircleOutlineSharpIcon 
                                    color='success'/>
                                </Tooltip>
                              : 
                                <Tooltip
                                  title="Not allowed">
                                  <CancelOutlinedIcon 
                                    color='error'/>
                                </Tooltip>         
                        }
                      </TableCell>
                     }
                    <TableCell>
                      {
                        row?.employee_status === "active"
                        ?
                          row?.guid === deleteId && deleting
                          ?
                          <CircularProgress size={26} sx={{ margin: "7px" }}/>
                          :
                          <IconButton
                            onClick={(e) => {
                              setEmployeesMenuAnchor(e.currentTarget);
                              setEmployeesMenuOpen(true);
                              setDeleteId(row?.guid);
                              setUpdate(row);
                              setModalTitle((row?.user?.first_name || "") + " " +
                                row?.user?.last_name || "");
                            }}>
                            <MoreVertIcon sx={{ color: global['purple']}}/>
                          </IconButton>
                        :
                        <></>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={employeesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={employeesMenuOpen}
        anchorEl={employeesMenuAnchor}
        onClose={() => setEmployeesMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setEmployeesMenuOpen(false);
            setEmployeesModalOpen(!employeesModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setEmployeesMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default UsersPage