import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Container,
  ListItem,
  ListItemText,
  MenuItem,
  Modal, 
  TextField, 
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';
import NA from '../../../../components/utils/NA';

import { 
  CreateChartOfAccount, 
  UpdateChartOfAccount 
} from '../../../../redux/actions/accounting/chartOfAccountsActions';

import "./styles.scss";

const Data = {
  AccountTypeId: "",
  Name: "",
  Description: "",
  Number: "",
};

const ChartOfAccountsModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.accounting);
  const companyId = useSelectedCompany();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);

  const [assetsAccountTypesList, setAssetsAccountTypesList] = useState([]);
  const [equityAccountTypesList, setEquityAccountTypesList] = useState([]);
  const [expensesAccountTypesList, setExpensesAccountTypesList] = useState([]);
  const [incomeAccountTypesList, setIncomeAccountTypesList] = useState([]);
  const [liabilitiesAccountTypesList, setLiabilitiesAccountTypesList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
  };

  useEffect(() => {
    if(open) {
      if(update?.defaultMode === 1){
        // mode = 1 - create category
        setMode(1);
      }else if(update?.defaultMode === 2){
        // mode = 2 - edit category
        setMode(2);
        setFormData({
          ...Data, 
          AccountTypeId: update.ref_account_type_id.id || "",
          Name: update.account_name || "",
          Description: update.account_description || "",
          Number: update.account_number || "",
        });
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatAccountsTypesList(state.accountTypesList.records || []);
    } catch (err) {}
  }, [state]);

  const formatAccountsTypesList = (list) => {
    setAssetsAccountTypesList(list.filter(i => i.account_category === "Assets"));
    setEquityAccountTypesList(list.filter(i => i.account_category === "Equity"));
    setExpensesAccountTypesList(list.filter(i => i.account_category === "Expenses"));
    setIncomeAccountTypesList(list.filter(i => i.account_category === "Income"));
    setLiabilitiesAccountTypesList(list.filter(i => i.account_category === "Liabilities"));
  };
    
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 -> create category
        let obj = {
          ref_account_type_id: formData.AccountTypeId || "",
          account_name: formData.Name || "",
          account_description: formData.Description || "",
          account_number: formData.Number || "",
        };
        dispatch(CreateChartOfAccount(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else if(mode === 2){
        // mode = 2 -> edit category
        let obj = {
          ref_account_type_id: formData.AccountTypeId || "",
          account_name: formData.Name || "",
          account_description: formData.Description || "",
          account_number: formData.Number || "",
        };
        dispatch(UpdateChartOfAccount(companyId, update?.id, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        className={`fullpage__modal animate__animated animate__fadeIn`}>
          
        <ModalTopBar
          onBack={handleClose}
          title={title} 
          />

        <Container 
          maxWidth="sm">
          <Box 
            className="flex__Column"
            gap={"20px"}
            pt="20px">
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}>
              <TextField 
                select
                label="Account type"
                fullWidth
                name="AccountTypeId"
                value={formData.AccountTypeId}
                onChange={handleChange}
                inputProps={{ maxLength: 64 }}
                >
                {/* Assets */}
                {
                  assetsAccountTypesList.length > 0
                  &&
                  <ListItem>
                    <ListItemText secondary="Assets" />
                  </ListItem>
                }
                { 
                  assetsAccountTypesList.length > 0
                  &&
                  assetsAccountTypesList.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.id}
                    sx={{ pl: "30px" }}>
                    {item.account_type || <NA />}
                  </MenuItem>
                ))}
                {/* Liabilities */}
                {
                  liabilitiesAccountTypesList.length > 0
                  &&
                  <ListItem>
                    <ListItemText secondary="Liabilities" />
                  </ListItem>
                }
                { 
                  liabilitiesAccountTypesList.length > 0
                  &&
                  liabilitiesAccountTypesList.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.id}
                    sx={{ pl: "30px" }}>
                    {item.account_type || <NA />}
                  </MenuItem>
                ))}
                {/* Income */}
                {
                  incomeAccountTypesList.length > 0
                  &&
                  <ListItem>
                    <ListItemText secondary="Income" />
                  </ListItem>
                }
                { 
                  incomeAccountTypesList.length > 0
                  &&
                  incomeAccountTypesList.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.id}
                    sx={{ pl: "30px" }}>
                    {item.account_type || <NA />}
                  </MenuItem>
                ))}
                {/* Expenses */}
                {
                  expensesAccountTypesList.length > 0
                  &&
                  <ListItem>
                    <ListItemText secondary="Expenses" />
                  </ListItem>
                }
                { 
                  expensesAccountTypesList.length > 0
                  &&
                  expensesAccountTypesList.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.id}
                    sx={{ pl: "30px" }}>
                    {item.account_type || <NA />}
                  </MenuItem>
                ))}
                {/* Equity */}
                {
                  equityAccountTypesList.length > 0
                  &&
                  <ListItem>
                    <ListItemText secondary="Equity" />
                  </ListItem>
                }
                { 
                  equityAccountTypesList.length > 0
                  &&
                  equityAccountTypesList.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.id}
                    sx={{ pl: "30px" }}>
                    {item.account_type || <NA />}
                  </MenuItem>
                ))}               
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}>
              <TextField 
                label="Account name"
                fullWidth
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
              <TextField 
                label="Account number"
                fullWidth
                name="Number"
                value={formData.Number}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}>
              <TextField 
                label="Account description"
                fullWidth
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                inputProps={{ maxLength: 256 }}
                />
            </Box>
            <LoadingButton
              variant="contained"
              loading={loading}
              disabled={!(formData.AccountTypeId !== "" && formData.Name !== "")}
              onClick={handleSubmit}>
              {mode === 1 ? "Submit" : "Update"}
            </LoadingButton>
          </Box>
        </Container>
      </Box>
    </Modal>
  )
}

export default ChartOfAccountsModal;