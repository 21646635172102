const DateFormatter = (date) => {
  const utcTime = new Date(date); // Example UTC time
  const usEasternTime = utcTime.toLocaleString("en-US", { timeZone: "America/New_York" });
  const usEasternTimeDate = new Date(usEasternTime);

  return (
    date 
    ? 
      usEasternTimeDate.toDateString().slice(4, 10) + ", " + usEasternTimeDate.getFullYear()
    :
      "NA"
  )
}

export default DateFormatter;