import React from 'react';

import { 
  Box,
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField, 
  Typography
} from '@mui/material';

import NA from '../utils/NA';

import "../../global.scss";

const AmountTextField = (props) => {
  let currenciesList = JSON.parse(localStorage.getItem("CurrencyList")) || [];

  function convertStringToFormattedAmount(amount){
    const max_amount_limit = 6;

    let cleanedAmount;
    cleanedAmount = amount.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '.')

    // Split the amount into the integer and decimal parts
    let parts = cleanedAmount.split('.');
    let integerPart = parts[0].slice(0, max_amount_limit);
    let decimalPart = parts.length > 1 ? parts[1] : '';

    // Limit the decimal part to two digits
    const selectedCurrency = currenciesList.find((cur) => cur.code === props.currency);
    if(selectedCurrency) decimalPart = decimalPart.substring(0, selectedCurrency.decimalPosition || 2);

    // Add commas to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine the integer and decimal parts
    let formattedAmount = parts.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
    return formattedAmount;
  };

  function getCurrencySymbol () {
    const currency = currenciesList.find((cur) => cur.code === props.currency);
    if(currency){
      return currency.symbol || props.currency;
    } else {
      return props.currency;
    }
  };

  return (
    <TextField 
      { ...props }
      type='text'
      value={props.value ? (props.value+"")?.replace(/[^0-9,.]/g, "") : ""}
      onChange={(e) => {
        e.target.value = convertStringToFormattedAmount(e.target.value);
        props.onChange(e);
      }}
      InputProps={{
        startAdornment: 
          <Box
            className="flexCenter_Row"
            gap={"5px"}
            mr={"4px"}>
            {
              props.hideCurrencySelector === true
              ?
              <></>
              :
              <TextField
                select
                size='small'
                variant='standard'
                name={props.currencyName}
                value={props.currency}
                disabled={props.disableCurrencySelector === true}
                onChange={(e) => props.onCurrencyChange(e)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "grey", // Change text color
                    padding: "5px",
                    borderRadius: "8px",
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "transparent", // Remove background color
                    borderRadius: "8px",
                  },
                  "& .MuiInputBase-root:hover": {
                    backgroundColor: "#f1f1f1", // Remove background color on hover
                  },
                  "& .MuiInputBase-root.Mui-focused": {
                    backgroundColor: "transparent", // Remove background color when focused
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                SelectProps={{
                  renderValue: (value) => {
                    if(currenciesList && currenciesList.length > 0){
                      const currency = currenciesList.find((cur) => cur.code === value);
                      return currency 
                        ? <Box>
                            <Typography
                              variant='body1'
                              color={"grey"}>
                              {currency.code || <NA />}
                            </Typography>
                          </Box>
                        : ''; 
                    } else {
                      return "";
                    }
                  },
                }}>
                {
                  currenciesList
                  &&
                  currenciesList.map((currency, currencyIndex) => (
                    <MenuItem
                      key={currencyIndex}
                      value={currency.code}>
                      <span>
                        {currency.name || <NA />}
                      </span>
                      &nbsp;
                      ({currency.code || <NA />})
                    </MenuItem>
                  ))
                }
              </TextField>
            }
            <Typography
              color={props.disabled ? "grey" : "black"}>
              { getCurrencySymbol() }
            </Typography>
          </Box>,
        endAdornment: (
          <InputAdornment position="end">
            {props.loading && <CircularProgress size={19} />}
          </InputAdornment>
        ),
      }}
      />
  )
}

export default AmountTextField;