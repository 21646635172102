import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import OTPInput from 'react-otp-input';

import { 
  Box,
  Button,
  Link,
  Container, 
  TextField, 
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  LinearProgress
} from '@mui/material';

import Logo from "../../assets/icons/logo-blue-dark2.svg";
import LoadingButton from '../../components/buttons/Button';
import useStepperInit from '../../components/hooks/useStepperInit';

import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { countryList } from '../../components/utils/countryCodeList';

import { 
  Register, 
  SendRegisterOtp, 
  VerifyRegisterOtp
} from '../../redux/actions/authActions';

import global from "../../global.scss";
import "./styles.scss";

const Data = {
  Email: "",
  CountryCode: "1",
  Mobile: "",
  LastName: "",
  FirstName: "",
  Password: "",
}

const RegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const [otp, setOtp] = useState("");
  const [refId, setRefId] = useState("");

  const [
    // eslint-disable-next-line no-unused-vars
    steps,
    activeStep, 
    handleReset, 
    handleNext,
    handleBack
  ] = useStepperInit(['Email', 'Details', 'Password', 'Verify', 'Successs']);

  useEffect(() => {
    handleReset();
    setFormData(Data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    if(e.target.name === "FirstName" || e.target.name === "LastName"){
      setFormData({ ...formData, [e.target.name]: e.target.value.replace(/[^a-zA-Z]/g, "") });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };  

  // ====================== handle submit ======================
  const handleSubmit = () => {
    console.log("submit");
    let obj = {
      "password": formData.Password,
      "first_name": formData.FirstName,
      "last_name": formData.LastName,
      "mobile": formData.Mobile,
      "email": formData.Email,
      "country_code": formData.CountryCode || "+1"
    };

    let otpObj = {
      "to": formData.Email
    };

    dispatch(Register(obj, setLoading)).then(({res, statusCode}) => {
      if(statusCode === 201){
        dispatch(SendRegisterOtp(otpObj, setLoading)).then(({res, statusCode}) => {
          if(statusCode === 201){
            handleNext();
            setRefId(res.data.refId);
          }
        });
      }
    });
  };


  const handleVerifyOtp = () => {
    let obj = {
      "ref_id": refId,
      "to": formData.Email,
      "otp": parseInt(otp+"")
    }

    dispatch(VerifyRegisterOtp(obj, setLoading)).then(({res, statusCode}) => {
      if(statusCode === 202){
        handleNext();
        setFormData(Data);
      }
    })
  };

  return (
    <Box>
      <Box className='auth__top'>
        <Container maxWidth="lg">
          <Box className='flexCenterSBRow'>
            <img
              src={Logo}
              alt="lusid-logo"
              style={{ width: "140px" }}
              />
            <Typography variant='h5' sx={{ color: global["secondary"] }}>
              {"Business"}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container 
        maxWidth="sm">

        <Box className="auth flexCenterCenterRow">
          <Box className="auth__content">
            <Typography
              variant='h5'
              textAlign={"center"}
              mb={"30px"}>
              Create new account
            </Typography>
            {/* <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper> */}

            <Box
              className="auth__content-step flexCenter_Column" 
              gap={"15px"}>

              <LinearProgress 
                sx={{ width: "100%" }}
                variant="determinate"
                value={ activeStep * 25 } 
                />

            {
              activeStep === 0
              ?
                <>
                  <Typography
                    mt={"20px"}
                    variant='subtitle2'
                    textAlign={"center"}
                    >
                    Provide a businses email address
                  </Typography>
                  <TextField 
                    variant="outlined"
                    // label="Email"
                    name="Email"
                    fullWidth
                    placeholder='Enter your primary email'
                    value={formData.Email}
                    onChange={handleChange}
                    />
                  </>
              : 
                activeStep === 1
                ?
                <>
                  <Typography
                    mt={"20px"}
                    variant='subtitle2'
                    textAlign={"center"}>
                    Enter your company details
                  </Typography>
                  <Box
                    className="flexCenter_Row" gap={"15px"} width={"100%"}>
                    <TextField 
                      variant="outlined"
                      label="First name"
                      name="FirstName"
                      fullWidth
                      placeholder='Enter your first name'
                      value={formData.FirstName}
                      onChange={handleChange}
                      />
                    <TextField 
                      variant="outlined"
                      label="Last name"
                      name="LastName"
                      fullWidth
                      placeholder='Enter your last name'
                      value={formData.LastName}
                      onChange={handleChange}
                      />
                  </Box>
                  <Box
                    className="flexCenter_Row" gap={"15px"} width={"100%"}>
                    <FormControl sx={{ width: "150px" }}>
                      <InputLabel id="country-code-label">Country</InputLabel>
                      <Select
                        labelId="country-code-label"
                        value={formData.CountryCode}
                        label="Country"
                        disabled
                        name="CountryCode"
                        onChange={handleChange}
                        renderValue={(item) => "+" + item}>
                        {
                          countryList.map((item, index) => (
                            <MenuItem key={index} value={item.dial_code}>
                              <span style={{ fontSize: "0px" }}>{item.name}</span>
                              <Box className="flexCenter_Row">
                                <Typography sx={{ width: "60px" }}
                                  variant='body2'>
                                  (+{item.dial_code})&nbsp;
                                </Typography>
                                <Typography variant='body2'>{item.name}</Typography>
                              </Box>
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                    <TextField 
                      variant="outlined"
                      label="Mobile number"
                      name="Mobile"
                      fullWidth={true}
                      placeholder='Enter your mobile number'
                      value={formData.Mobile}
                      onChange={handleChange}
                      />
                  </Box>
                </>
                :
                  activeStep === 2
                  ?
                  <>
                    <Typography
                      mt={"20px"}
                      variant='subtitle2'
                      textAlign={"center"}>
                      Enter password for your account
                    </Typography>
                    <TextField 
                      variant="outlined"
                      label="Password"
                      type={passwordShow ? "text" : "password"}
                      name="Password"
                      fullWidth
                      placeholder='Enter your password'
                      value={formData.Password}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: 
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                setPasswordShow(!passwordShow);
                              }}>
                            {
                              passwordShow
                              ? <VisibilityOffOutlinedIcon />   
                              : <VisibilityOutlinedIcon />
                            }
                            </IconButton>
                          </InputAdornment>,
                      }}
                      />
                    <TextField 
                      variant="outlined"
                      label="Confirm Password"
                      name="ConfirmPassword"
                      type={confirmPasswordShow ? "text" : "password"}
                      fullWidth
                      placeholder='Enter your confirm password'
                      value={formData.ConfirmPassword}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: 
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                setConfirmPasswordShow(!confirmPasswordShow);
                              }}>
                            {
                              confirmPasswordShow
                              ? <VisibilityOffOutlinedIcon />   
                              : <VisibilityOutlinedIcon />
                            }
                            </IconButton>
                          </InputAdornment>,
                      }}
                      />
                    <Typography
                      variant='caption'
                      textAlign={"center"}
                      color={"grey"}
                      mb={"20px"}>
                      Note: Password should have minimum 8 characters.
                    </Typography>
                  </>
                  :
                    activeStep === 3
                    ?
                    <>
                      <Typography
                        mt={"20px"}
                        variant='subtitle2'
                        textAlign={"center"}>
                        Enter verification code sent on email - {formData.Email}
                      </Typography>
                      <OTPInput
                        value={otp}
                        onChange={(e) => {setOtp(e)}}
                        shouldAutoFocus={true}
                        numInputs={6}
                        isInputSecure={true}
                        containerStyle={"auth__content-otp"}
                        inputStyle={"auth__content-otp-input"}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />
                        }
                      />
                    </>
                    :
                      activeStep === 4
                      ?
                      <>
                        <Box
                          height={"250px"}
                          className="flexCenterCenterColumn"
                          gap={"20px"}>
                          <VerifiedTwoToneIcon 
                            color='success'
                            sx={{ width: "70px", height: "70px" }}/>

                          <Typography variant='subtitle1'>
                            Your account is ready for login
                          </Typography>
                          <Box
                            sx={{ width: "125px" }}>
                            <Button
                              variant='contained'
                              onClick={() => navigate("/auth")}>
                                Login
                            </Button>
                          </Box>
                        </Box>
                      </>
                      :
                      <></>
            }
            </Box>
            <Box
              className="flex__Column"
              gap={"20px"}
              width={"400px"}
              margin={"auto"}
              marginTop={"20px"}>
              <Box
                className="flexCenterFERow"
                gap={"15px"}>
                {
                  (activeStep === 1 || activeStep === 2)
                  &&
                  <Button
                    variant='text'
                    onClick={handleBack}>
                    Back
                  </Button>
                }
                {
                  activeStep < 4
                  &&
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    onClick={
                      activeStep === 3
                      ? handleVerifyOtp
                      : activeStep === 2
                        ? handleSubmit
                        : handleNext }
                    disabled={
                      activeStep === 0
                      ? !(formData.Email !== "")
                        : activeStep === 1
                          ? !(formData.FirstName !== "" && formData.LastName !== "" && 
                              formData.CountryCode !== "" && formData.Mobile.length === 10)
                          : activeStep === 2
                            ? !(formData.Password?.length > 7 && formData.ConfirmPassword?.length > 7 && 
                                  formData.Password === formData.ConfirmPassword)
                            : activeStep === 3
                              ? !(otp.length === 6)
                              : false
                    }>
                    { activeStep === 3 ? "Verify" : activeStep === 2 ? "Continue" : "Next" }
                  </LoadingButton>
                }
              </Box>
              {
                activeStep < 3
                &&
                <Link
                  marginRight={"auto"}
                  onClick={() => navigate("/auth")}>
                  Already have an account?
                </Link>
              }
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default RegisterPage;